@import '_variables.scss';

.about-wrapper {
	width: 100%;
	background-color: $primary_background;
	display: flex;
	box-shadow: $global-dark-shadow;

	.map-link {
		height: 100vh;
		width: 50%;

		#jsMap {
			height: 100vh;
			pointer-events: none;
		}
	}

	.about-container {
		width: 50%;
		padding: 0 3rem;
		margin: auto;
		
		p {
			font-size: 16px;
			line-height: 3rem;
			text-align: left;
			letter-spacing: 1px;
			color: #f5f5f5;
		}
	}

	@media(max-width: 979px) {
		display: block;

		.map-link {
			height: 50vh;
			width: 100%;

			#jsMap {
				height: 50vh;
				width: 100%;
			}	
		}

		.about-container {
			width: 100%;
			padding: 6rem 2rem;

			p {
				line-height: 2.5rem;
				font-size: 14px;
				text-align: center;
			}
		}
	}
}