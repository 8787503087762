.modal-wrapper {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	background-color: #00000000;
	z-index: 10;
	pointer-events: none;
	transition: all 200ms ease-in-out;

	&.open {
		background-color: #000000db;
		pointer-events: all;

		.image-container {
			opacity: 1;
		}
	}

	.image-container {
		user-select: none;
		display: flex;
		height: 100%;
		margin: auto;
		opacity: 0;
		transition: all 400ms ease-in-out;

		img {	
			max-width: 500px;
			margin: auto;

			@media (max-width: 767px) {
				max-width: 300px;
				width: 100%;
			}
		}
	}
}
