@import '_variables.scss';

header {
	display: flex;
	height: 70px;
	width: 100%;
	z-index: 10;
	position: fixed;
	top: 0;
	z-index: 11;

	picture {
		position: absolute;
		width: 100%;
		
		.logo {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%);
			z-index: 10;
		}
	}

	.nav-links {
		width: 100%;
		height: 100%;
		display: flex;
		cursor: pointer;

		.navbar {
			display: flex;
			width: 100%;
			margin-bottom: 0;
			justify-content: space-between;

			.nav-section {
				position: relative;
				width: 40%;
				display: flex;
				justify-content: center;

				.link {
					margin: auto 2rem;
					user-select: none;

					:hover, :focus {
						text-decoration: none;
					}

					a {
						text-decoration: none;
						color: rgba(255, 255, 255, 0.40);
						font-size: 2rem;
						letter-spacing: 2px;
						margin: 0;
						transition: all 300ms ease;

						&.active {
							color: white;
						}
					}
				}
			}
		}
	}

	.menu-icon {
		display: none;
	}

	@media (min-width: 768px) {		
		.logo {
			width: 500px;
		}
	}

	@media (max-width: 767px) {
		.menu-icon {
			height: 70px;
			position: absolute;
			right: 10%;
			display: flex;

			.fas, .fa-bars {
				font-size: 35px;
				color: #e6e6e6;
				margin: auto;
			}
		}

		.logo {
			width: 300px;
		}

		.nav-links {
			height: 100vh;
			background-color: $primary_background;
			position: absolute;
			top: -100vh;
			transition: all 400ms ease-in;

			&.open {
				top: 0;
			}

			.navbar {
				text-align: center;
				display: block !important;
				margin: auto;
				margin-bottom: auto !important;

				.nav-section {
					width: 100% !important;
					display: block !important;

					.link {
						margin: 5rem;
					}
				}
			}
		}
	}
}
