@import '_variables.scss';

.full-block-wrapper {
	position: relative;
	top: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	box-shadow: $global-dark-shadow;
	z-index: 10;

	.dark-overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0,0,0,0.6);
		z-index: 1;
	}

	.background-image-wrapper {
		height: 100%;
		width: 100%;



		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			min-height: 100%;

			@media (min-width: 1200px) {
				width: 100%;
			}
		}
	}
}
