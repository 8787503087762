@import '_variables.scss';

.menu-container {
	padding: 6rem 0;
	min-height: 100vh;

	.row {
		height: 100%;

		.menu-sidebar {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			
			h2 {
				border-bottom: 4px solid $primary_background;
				padding-bottom: 1rem;
			}

			h4 {
				user-select: none;
				cursor: pointer;
				margin: 1.5rem 0;
				text-align: right;
				transition: all 300ms ease;

				&.active-category {
					font-size: 24px;
					font-weight: bold;
				}
			}

			@media (max-width: 767px) {
				text-align: center;
				white-space: nowrap;
				overflow-x: auto;
				display: block;

				h2 {
					display: none;
				}

				h4 {
					display: inline-block;
					margin: 4rem 2rem !important;
				}

				&::-webkit-scrollbar {
					display: none;
				}
			}
		}

		.menu-display-wrapper {
			overflow: hidden;
			
			.menu-list {
				position: absolute;
				margin: 0 2rem;
				opacity: 0;
				height: 100%;
				z-index: 8;
				transition: all 300ms ease-in-out;

				.menu-item {
					display: flex;
					flex-direction: column;
					margin-bottom: 2rem;

					h4 {
						font-weight: 600;
					}

					.menu-header {
						padding: 0 1rem;
						align-items: center;
					
						.item-price {
							font-weight: normal;
							font-size: 16px;
						}
					}

					@media (max-width: 767px) {
						h4 {
							font-size: 16px;
						}

						.menu-header {
							width: 100%;
							justify-content: space-between;

							p {
								font-size: 14px;
							}
						}
					}
				}

				&.active-list {
					position: relative;
					opacity: 1;
				}

				&::-webkit-scrollbar {
					display: none;
				}
			}
		}
	}
}