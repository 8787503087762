h2 {
	font-size: 30px;
	font-weight: normal;
}

h3 {
	font-size: 24px;
	font-weight: normal;
}

h4 {
	font-size: 20px;
	font-weight: normal;
}

p {
	font-size: 14px;
	font-weight: normal;
}

li {
	font-size: 14px;
	font-weight: normal;
}
