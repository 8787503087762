@import "_variables.scss";

* {
	box-sizing: border-box;
}

html, body, main {
	min-height: 100%;
	width: 100%;
	overflow-x: hidden;
	margin: 0;
	font-size: 10px;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
	-webkit-overflow-scrolling: touch;
	background-color: #f5f5f5;

	section {
		position: relative;
		padding-top: 150px;
		padding-bottom: 150px;
	}
}


.popup-message-container {
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 1rem 4rem;
	display: flex;
	justify-content: center;
	background-color: $primary_background;
	z-index: 100;
	color: white;
	text-align: center;

	.close {
		position: absolute;
		right: 0;
		top: 50%;
		height: 100%;
		width: 4rem;
		transform: translateY(-50%);
		color: white;
		outline: none;
		background-color: transparent;
		border: none;
	}
}

picture:not(#logo) {
	transition: all 300ms ease-in;

	img {
		transition: all 300ms ease-in;
	}
}

.social-media-wrapper {
	display: flex;
	margin: 1rem auto;
	justify-content: center;
	padding: 2rem;
}

.social-media-wrapper-events {
	display: flex;
	margin: 1rem auto;
}

.icon {
	width: 60px;
	height: 60px;
	padding: 5px;
	background-size: contain;
	background-repeat: no-repeat;
	user-select: none;
	transition: all 300ms ease;
	margin: 2rem;

	&:hover {
		transform: scale(1.2);
		background-color: white;
		border-radius: 75%;
	}
}

.lazy {
	opacity: 0;
	transition: all 200ms ease-in;
}

.loaded {
	opacity: 1;
}

.error-wrapper {
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 2rem;

	h2 {
		font-size: 50px;
		color: $primary_background;
	}

	p {
		font-size: 30px;
		text-align: center;
	}
}

.loading-wrapper {
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
}
